import $ from 'jquery';
import { getServices, getActiveServices } from '../apis/marketPlace';
import { getPermissionsData } from '../apis/rbac';
import { getCookie } from '../utils/common';
import { getUserData } from '../apis/profile';
import { getTrainingsActiveStatus } from '../apis/lms';
import { isDefaultAvailable } from '../apis/regulatoryIntelligence';
import { checkUserRestriction } from '../apis/team';

const NotAuthorizedPaths = [
  'login',
  'register',
  'forgot',
  'reset',
  'confirmregister',
  'termsofservice',
  'verificationlink'
];
async function validateRBAC(to) {
  let permissionsFlag = false;
  if (
    to.meta.title === 'Registration Tracker' ||
    to.meta.title === 'Regulatory Watch' ||
    to.meta.title === 'Product Classification' ||
    to.meta.title === 'Regulatory Reports' ||
    to.meta.title === 'Regulatory Intelligence' ||
    to.meta.title === 'Launch Pad' ||
    to.meta.title === 'Activity Tracker' ||
    to.meta.title === 'Regulatory Watch Admin' ||
    to.meta.title === 'Representation Resources' ||
    to.meta.title === 'Global Search'
  ) {
    const servicesAndModulesList = [];
    const permissionsList = await getPermissionsData();
    permissionsList.forEach((service) => {
      if (service !== null && service.access) {
        servicesAndModulesList.push(service.key);
        service.modules.forEach((module) => {
          if (module !== null && module.access) {
            servicesAndModulesList.push(module.key);
          }
        });
      }
    });
    switch (to.path) {
      case '/layout/trackplusdashboard':
        if (servicesAndModulesList.indexOf('REG_TRACKER') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/registration':
        if (servicesAndModulesList.indexOf('REG_TRACKER_RECORDS') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/report/summary':
        if (servicesAndModulesList.indexOf('REG_TRACKER_REPORTS') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/report/device':
        if (servicesAndModulesList.indexOf('REG_TRACKER_REPORTS') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/report/country':
        if (servicesAndModulesList.indexOf('REG_TRACKER_REPORTS') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/regulatory-updates':
        if (servicesAndModulesList.indexOf('REGULATORY_UPDATES') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/prodclassification':
        if (servicesAndModulesList.indexOf('CLASSIFICATION') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/regulatory-pathway':
        if (servicesAndModulesList.indexOf('SELECT_REPORT_CRITERIA') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/regulatoryprocesscharts':
        if (servicesAndModulesList.indexOf('REG_PROCESS_CHARTS') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/regulatoryfaqs':
        if (servicesAndModulesList.indexOf('REGULATORY_FAQS') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/launchpad':
        if (servicesAndModulesList.indexOf('LAUNCHPAD') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/activitytracker':
        if (servicesAndModulesList.indexOf('LAUNCHPAD_ACTIVITY_TRACKER') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/regulatoryUpdatesAdmin':
        if (servicesAndModulesList.indexOf('REGULATORY_WATCH_ADMIN') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/representationresources/producttracker':
        if (servicesAndModulesList.indexOf('REPRESENTATION_RESOURCES_PRODUCT_TRACKER') === -1) {
          permissionsFlag = true;
        }
        break;
      case '/layout/representationresources/agreements':
        if (servicesAndModulesList.indexOf('REPRESENTATION_RESOURCES_AGREEMENTS') === -1) {
          permissionsFlag = true;
        }
        break;
      default:
        break;
    }
  }
  return permissionsFlag;
}
function checkAuthorization(to) {
  return NotAuthorizedPaths.indexOf(to.name) > -1 && getCookie('Authorization');
}
export const pathNotAllowed = [
  'Regulatory Reports',
  'Registration Tracker',
  'Regulatory Watch',
  'Regulatory Intelligence',
  'Smart Builder',
  'Product Classification',
  'Product Classification Rationale Report (standard)',
  'Product Classification Rationale Report (Custom )',
  'Trainings',
];
async function checkPath(to, userData) {
  window.emitter.emit('loadStatus', true);
  const services = JSON.parse(localStorage.getItem('services'))
    ? JSON.parse(localStorage.getItem('services'))
    : await getServices();
  const accoountServices = await getActiveServices(localStorage.getItem('accountId'));
  const addedServiceIds = accoountServices.services || [];
  const isDefaultMarket = await isDefaultAvailable(userData.account_id);
  const serviceIndex = services.findIndex((service) => service.name === to.meta.title);
  const serviceId = services[serviceIndex].id;
  if (isDefaultMarket && serviceId === 2) {
    addedServiceIds.push(2);
  }
  const addedIndex = addedServiceIds.findIndex((addservice) => addservice === serviceId);
  // window.emitter.emit('loadStatus', false);
  return {addedIndex,serviceId};
}
export async function beforeEach(to, from, next) {
  const roleId = parseInt(localStorage.getItem('roleId'), 10);
  if (process.env.VUE_APP_MODE === 'production' && to.path === '/' && !getCookie('Authorization')) {
    window.location.href = 'https://www.emergobyul.com/rams';
    return;
  }
  if (process.env.VUE_APP_MODE !== 'production' && to.path === '/') {
    next({ path: '/login' });
    return;
  }
  if (checkAuthorization(to)) {
    if (roleId && roleId === 11) {
      next({ path: '/layout/launchpad' });
      return;
    }
    next({ path: '/layout/dashboard' });
    return;
  }
  if(to.path !== '/layout/regulatory-pathway') sessionStorage.removeItem('isSampleRegulatoryReport');
    if (localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData'))) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Pageview',
        pagePath: to.path,
        pageTitle: to.meta.title,
        UserID: JSON.parse(localStorage.getItem('userId')),
        CompanyID: JSON.parse(localStorage.getItem('userData')).company
      });
    }
  if (to.meta.title === 'RAMSTour') {
    localStorage.removeItem('window.groove-widget-status');
  }
  if (getCookie('Authorization')) {
    const userData = localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData'))
      : await getUserData()
    if(to.meta.title !== 'Smart Docs') {
      sessionStorage.removeItem('lastCrumb');
    }
    if (roleId && [10, 15].includes(roleId)) {
      if (
        ['Smart Exchange', 'Global Search', 'Resources', 'Smart Builder', 'Smart Docs', 'Registration Tracker', 'Permission Access', 'Product Classification', 'Product Classification Summary'].includes(to.meta.title)
      ) {
        if(pathNotAllowed.indexOf(to.meta.title) >= 0 && ['Smart Builder', 'Registration Tracker'].includes(to.meta.title)) {
          const {addedIndex} = await checkPath(to, userData);
          if (addedIndex < 0) {
            if(from.path === '/') {
              next({
                path: '/layout/smart-exchange'
              });
            }else next(false);
            $('#serviceNotAddedPopup').modal({
              backdrop: 'static',
              keyboard: false
            });
            return;
          }
          if (await validateRBAC(to)) {
            next({
              path: '/layout/PermissionAccess'
            });
            return;
          } 
        }
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if (
        (to.meta.title === 'Representation Resources' || to.path.includes('ptemail')) &&
        userData.account_type_id === 2
      ) {
        if (await validateRBAC(to)) {
          next({
            path: '/layout/PermissionAccess'
          });
          return;
        }
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if (from.meta.title === 'Global Search') {
        next(false);
        $('#permissionAccessModal').modal({
          backdrop: 'static',
          keyboard: false
        });
        return;
      }
      next({
        path: '/layout/smart-exchange'
      });
      return;
    }
    if (roleId && [12, 13].includes(roleId)) {
      if (['Smart Exchange', 'Global Search', 'Resources', 'Smart Builder', 'Smart Docs','Permission Access'].includes(to.meta.title)) {
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if(pathNotAllowed.indexOf(to.meta.title) >= 0 && ['Smart Builder', 'Registration Tracker'].includes(to.meta.title)) {
        const {addedIndex} = await checkPath(to, userData);
        if (addedIndex < 0) {
          if(from.path === '/') {
            next({
              path: '/layout/smart-exchange'
            });
          }else next(false);
          $('#serviceNotAddedPopup').modal({
            backdrop: 'static',
            keyboard: false
          });
          return;
        }
        if (await validateRBAC(to)) {
          next({
            path: '/layout/PermissionAccess'
          });
          return;
        } 
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if ((to.meta.title === 'Representation Resources' || to.path.includes('ptemail')) &&
          userData.account_type_id === 2) {
        if (await validateRBAC(to)) {
          next({
            path: '/layout/PermissionAccess'
          });
          return;
        }
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if (from.meta.title === 'Global Search') {
        next(false);
        $('#permissionAccessModal').modal({
          backdrop: 'static',
          keyboard: false
        });
        return;
      }
      next({
        path: '/layout/smart-exchange'
      });
      return;
    }
    if (roleId && roleId === 14) {
      if (['Global Search', 'Resources','Permission Access', 'Sales Dashboard'].includes(to.meta.title)) {
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if(pathNotAllowed.indexOf(to.meta.title) >= 0 && (to.meta.title === 'Registration Tracker' || to.meta.title === 'Regulatory Intelligence')) {
          const {addedIndex} = await checkPath(to, userData);
          if (addedIndex < 0) {
            if(from.path === '/') {
              next({
                path: '/layout/regsandguidance'
              });
            }else next(false);
            $('#serviceNotAddedPopup').modal({
              backdrop: 'static',
              keyboard: false
            });
            return;
          }
          if (await validateRBAC(to)) {
            next({
              path: '/layout/PermissionAccess'
            });
            return;
          }
          next();
          window.emitter.emit('activeMenuTitle', to.meta.title);
          return; 
        }
      if ((to.meta.title === 'Representation Resources' || to.path.includes('ptemail')) && userData.account_type_id === 2) {
        if (await validateRBAC(to)) {
          next({
            path: '/layout/PermissionAccess'
          });
          return;
        }
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if (from.meta.title === 'Global Search') {
        next(false);
        $('#permissionAccessModal').modal({
          backdrop: 'static',
          keyboard: false
        });
        return;
      }
      next({
        path: '/layout/sales/renewal-reports'
      });
      return;
    }
    if (pathNotAllowed.indexOf(to.meta.title) >= 0 && to.meta.title === 'Trainings') {
      if(userData.account_type_id !== 1 && userData.account_type_id !== 5){
        const response = await checkUserRestriction();
        if(response) {
          next({
            path: '/layout/RestrictionAccess'
          });
          return;
        }
      }
      const trainings = await getTrainingsActiveStatus(JSON.parse(localStorage.getItem('userId')));
      if (trainings.data === true) {
        next();
        return;
      }
      next({
        path: '/layout/market-place/service/12'
      });
    }
    if (pathNotAllowed.indexOf(to.meta.title) >= 0 && to.meta.title !== 'Trainings') {
      if(userData.account_type_id !== 1 && userData.account_type_id !== 5){
        const response = await checkUserRestriction();
        if(response) {
          next({
            path: '/layout/RestrictionAccess'
          });
          return;
        }
      }
      const {addedIndex,serviceId} = await checkPath(to, userData);
      if (addedIndex < 0 && to.path === '/layout/regulatory-pathway') {
        const isDemo = sessionStorage.getItem('isSampleRegulatoryReport');
        if(isDemo){
          next();
          window.emitter.emit('activeMenuTitle', to.meta.title);
          return;
        }
        next({
          path: '/layout/market-place/service/7'
        });
        return;
      }
      if (addedIndex < 0) {
        if ([10, 12, 13, 15].includes(roleId)) {
          $('#serviceNotAddedPopup').modal({
            backdrop: 'static',
            keyboard: false
          });
          return;
        }
        next({
          path: `/layout/market-place/service/${serviceId}`
        });
        return;
      }
      if (await validateRBAC(to)) {
        next({
          path: '/layout/PermissionAccess'
        });
        return;
      }
      next();
      window.emitter.emit('activeMenuTitle', to.meta.title);
    }
    if (from.path.includes('/report/') && to.path.includes('/report/')) {
      next();
      window.emitter.emit('activeMenuTitle', to.meta.title);
      return;
    }
    if(to.meta.title === 'Smart Docs') {
      if (roleId && roleId === 4) {
        next({ path: '/layout/report/summary' });
        return;
      }
      if([3, 4, 6, 7, 14].includes(roleId)) {
        next({ path: '/layout/dashboard' });
        return;
      }
      next();
      window.emitter.emit('activeMenuTitle', to.meta.title);
      return;
    }
    if(to.meta.title === 'Restriction Access') {
      const response = await checkUserRestriction();
      if(response) {
        next();
        return;
      }
      if (roleId && roleId === 11) {
        next({ path: '/layout/launchpad' });
        return;
      }
      if (roleId && roleId === 4) {
        next({ path: '/layout/report/summary' });
        return;
      }
      next({ path: '/layout/dashboard' });
      return;
    }
    if (to.meta.title === 'Regulatory Watch Admin') {
      if(![5,7].includes(roleId)) {
        next({
          path: '/layout/dashboard'
        });
        return;
      }
      if (await validateRBAC(to)) {
        next({
          path: '/layout/PermissionAccess'
        });
        return;
      }
      next();
      window.emitter.emit('activeMenuTitle', to.meta.title);
      return;
    }
    if (to.meta.title === 'Representation Resources') {
      if (userData.account_type_id === 2 || [5, 7].includes(roleId)) {
        if (await validateRBAC(to)) {
          next({
            path: '/layout/PermissionAccess'
          });
          return;
        }
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      if(from.meta.title === 'Global Search') {
        next(false);
        $('#permissionAccessModal').modal({
          backdrop: 'static',
          keyboard: false
        });
        return;
      }
      next({
        path: '/layout/dashboard'
      });
      return;
    }
    if (roleId && roleId === 11) {
      if (to.meta.title === 'Launch Pad' || to.meta.title === 'Activity Tracker') {
        if (await validateRBAC(to)) {
          next({
            path: '/layout/PermissionAccess'
          });
          return;
        }
        next();
        window.emitter.emit('activeMenuTitle', to.meta.title);
        return;
      }
      next();
      window.emitter.emit('activeMenuTitle', to.meta.title);
      return;
    }
    if (['Activity Tracker', 'Launch Pad'].includes(to.meta.title) && ![5, 11].includes(roleId)) {
      next({
        path: '/layout/dashboard'
      });
      return;
    }
    next();
    window.emitter.emit('activeMenuTitle', to.meta.title);
    return;
  }
  next();
}
