<template>
  <span>
    <img
      v-bind="$attrs"
      :src="`static/images/icons/${icon}.svg`"
      :class="imgClass"
      alt="dynamic-base-image"
    >
    <slot />
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: "",
    },
    imgClass: {
      type: String,
      default: "",
    },
  },
};
</script>
